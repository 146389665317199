import { ApolloClient, ApolloLink, from, split, type DefaultContext } from '@apollo/client/core'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import logger from 'logger'
import config from 'config'

import localStorage from 'local-storage'

import {
  createCFRFTokenLink,
  createHttpLink,
  createWSLink,
  createRetryLink,
  createTraceIdLink,
  createLocaleLink,
  type FetchFunction,
} from './util/links'
import { defaultTypePolicies } from './util/cache'


// ATTN required to use the latest patched by datadome window.fetch
const fetch: FetchFunction = (url, options) => window.fetch(url, options)

// https://www.apollographql.com/docs/link/links/error/
// https://www.apollographql.com/docs/link/links/http/#errors
const createErrorLink = (name: string) => onError((error) => {
  const { operation, graphQLErrors, networkError } = error
  const { placement } = operation.getContext()

  if (graphQLErrors) {
    logger.error({ errors: graphQLErrors }, `[${name}]: ${operation.operationName} error${placement ? `- ${placement}` : ''}`)
  }

  if (networkError) {
    logger.warn(networkError, `[${name}]: ${operation.operationName} failed because of network error: ${networkError.message}`)
  }
})

type CreateClientOptions = {
  getLocale: () => string
  enableCaptcha?: boolean
}

const createDefaultClient = ({ getLocale, enableCaptcha = false }: CreateClientOptions) => {
  const httpLink = createHttpLink({
    name: 'GraphQL',
    fetch,
    uri: config.publicApi,
    timeout: config.publicApiTimeout,
  })

  // adds headers to all requests
  const headersLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const result: DefaultContext = {
        headers: {
          ...headers,
          'scnt-current-page-url': window.location.pathname,
        },
      }

      if (enableCaptcha) {
        result.searchParams = 'captcha=1'
      }

      return result
    })

    return forward(operation)
  })

  const wsLink = createWSLink({
    uri: config.publicWSApi,
    connectionParams: () => {
      return {
        'X-Auth-Token': localStorage.getSessionItem('tokenApi'),
        'x-locale': getLocale(),
      }
    },
  })

  // Alternative authentication method
  // const subscriptionMiddleware = {
  //   applyMiddleware: function(options, next) {
  //     options['X-Auth-Token'] = localStorage.getSessionItem('tokenApi')
  //     next()
  //   },
  // }
  //
  // wsLink.subscriptionClient.use([ subscriptionMiddleware ])

  const splitLink = split(
    ({ getContext }) => {
      // small optimization, because we use subscription only for payment session
      const context = getContext()
      return context.webSocket
    },
    wsLink,
    from([
      createLocaleLink(getLocale),
      createRetryLink(),
      createCFRFTokenLink(),
      headersLink,
      createTraceIdLink(),
      httpLink,
    ])
  )

  const link = from([
    createErrorLink('GraphQL'),
    splitLink,
  ])

  const cache = new InMemoryCache({
    typePolicies: defaultTypePolicies,
  })

  return new ApolloClient({
    cache: cache.restore(window.__APOLLO_STATE__.default),
    link,
    assumeImmutableResults: true,
    devtools: {
      enabled: true,
      name: 'Default',
    },
  })
}

const createStrapiClient = () => {
  const link = from([
    createErrorLink('Strapi'),
    createRetryLink(),
    createTraceIdLink(),
    createHttpLink({
      name: 'Strapi',
      fetch,
      uri: config.publicStrapiApi,
      timeout: config.publicApiTimeout,
    }),
  ])

  const cache = new InMemoryCache({
    typePolicies: defaultTypePolicies,
  })

  return new ApolloClient({
    cache: cache.restore(window.__APOLLO_STATE__.strapi),
    link,
    assumeImmutableResults: true,
    devtools: {
      enabled: true,
      name: 'Strapi',
    },
  })
}

export const createClients = (options: CreateClientOptions) => {
  return {
    default: createDefaultClient(options),
    strapi: createStrapiClient(),
  }
}
